<script>
import { addScript } from '@/helpers/load-script-and-style'
import { mapState, mapMutations } from 'vuex'
import { EPMC_ROOT, EXPORT_LIST_LIMIT, SEARCH_FIELDS } from '@/config'
import { fetchSearchResults, fetchSnippets } from '@/api'
import { highlightByQuery } from 'js-solr-highlighter'
import { hasQueryVal, replaceUrlQuery } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
import WindowResize from '@/helpers/window-resize'
import Sticky from '@/helpers/sticky'
import { setIsBeta } from '@/helpers/sessionStorage'
import { convertLatexToMathMl, stripMMLPrefix } from '@/helpers/latex'
import {
  Action,
  List,
  Loading,
  Notification,
  Pagination,
  Tooltip,
} from 'epmc-patterns/components/v2'
import ActionBar from '@/templates/ActionBar'
import Citation from '@/templates/Citation'
import AuthorInfo from '@/templates/search/AuthorInfo'
import SearchFilters from '@/templates/search/SearchFilters'
import BetaSearchFilters from '@/templates/search/BetaSearchFilters'
import SuggestedAuthors from '@/templates/search/SuggestedAuthors'
import SuggestedSearch from '@/templates/search/SuggestedSearch'
import StickyFooter from '@/templates/StickyFooter'

export default {
  metaInfo() {
    return {
      title: this.query,
      titleTemplate: '%s - Search results - Europe PMC',
      meta: [{ name: 'description', content: this.query }],
    }
  },
  components: {
    Action,
    ActionBar,
    AuthorInfo,
    Citation,
    List,
    Loading,
    Notification,
    Pagination,
    SearchFilters,
    SuggestedAuthors,
    SuggestedSearch,
    Tooltip,
    StickyFooter,
    BetaSearchFilters,
  },
  mixins: [Sticky, WindowResize],
  props: {
    query: {
      type: String,
      default: '',
    },
    page: {
      type: String,
      default: '1',
    },
    sortBy: {
      type: String,
      default: 'Relevance',
    },
    synonym: {
      type: String,
      default: 'FALSE',
    },
    maxPageJump: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maxPageJumpLimit: 40,
      pageLimit: 25,
      currentPage: 1,
      nextCursorMark: '*',
      loading: true,
      hitCount: 0,
      notify: null,
      filterOptions: [
        {
          text: 'Relevance',
          value: 'Relevance',
          selected: true,
          tooltipText:
            'This is determined based on a ranking of criteria including term frequency, rarity of term, recency of article, whether there is a Title or Author field match, etc.',
        },
        { text: 'Times cited', value: 'CITED+desc', selected: false },
        {
          text: 'Date',
          value: 'FIRST_PDATE_D+desc',
          selected: false,
        },
      ],
      citations: [],
      EXPORT_LIST_LIMIT,
      schemaList: {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [],
      },
    }
  },
  computed: {
    ...mapState('exportList', ['exportList']),
    ...mapState('searchSessions', ['lastSortBy']),
    // only the "original" query will be passed into the author info, suggested authors and did-you-mean blocks as well as the recent search list.
    queryWithoutConstraints() {
      return this.query
        .split(' AND')[0]
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .trim()
    },
    previousQuery() {
      return this.$route.query.previousQuery
        ? this.$route.query.previousQuery
        : ''
    },
    currentSortBy() {
      const { lastSortBy, sortBy } = this
      return (!hasQueryVal('sortBy') && lastSortBy) || sortBy
    },
    currentSortOption() {
      return this.filterOptions.find((option) => option.selected)
    },
  },
  watch: {
    $route: 'getCitations',
  },
  created() {
    setIsBeta(true)
    this.getCitations()
  },
  methods: {
    ...mapMutations('searchSessions', ['changeLastSortBy', 'changeSnippets']),
    ...mapMutations('exportList', [
      'addSelectedArticle',
      'removeSelectedArticle',
    ]),
    ...mapMutations('search', ['changeLastSearch']),
    getCitations() {
      const {
        maxPageJumpLimit,
        pageLimit,
        query,
        page,
        currentSortBy,
        currentPage,
        synonym,
        filterOptions,
      } = this

      // set sorter
      let selectedFilterOption = null
      if (currentSortBy === 'FIRST_PDATE_D+asc') {
        selectedFilterOption = filterOptions.find(
          (option) => option.text === 'Date'
        )
        selectedFilterOption.value = 'FIRST_PDATE_D+asc'
      } else {
        selectedFilterOption = filterOptions.find(
          (option) => option.value === currentSortBy
        )
      }
      if (selectedFilterOption) {
        filterOptions.forEach((option) => {
          option.selected = false
        })
        selectedFilterOption.selected = true
      }

      // a hacky way to get synonym from the advanced search
      try {
        if (sessionStorage.getItem('synonym')) {
          sessionStorage.removeItem('synonym')
          this.$router.push({
            path: 'betaSearch' + replaceUrlQuery({ synonym: 'true' }),
          })
        }
      } catch (e) {}

      // prevent too big page jump ONLY WHEN refreshing with "large" page number; currentPage is 1 here
      if (page - currentPage > maxPageJumpLimit) {
        this.$router.push({
          path:
            'betaSearch' +
            replaceUrlQuery({ page: maxPageJumpLimit, maxPageJump: 'true' }),
        })
      } else {
        const { nextCursorMark } = this
        const pageGap = page - currentPage
        // derive cursorMark and pageSize required in the request
        const cursorMark = page > currentPage ? nextCursorMark : '*'
        const pageSize =
          page > currentPage
            ? nextCursorMark === '*'
              ? (pageGap + 1) * pageLimit
              : pageGap * pageLimit
            : page * pageLimit
        this.loading = true
        fetchSearchResults({
          cursorMark,
          pageSize,
          query,
          sort: currentSortBy,
          synonym,
        })
          .then((response) => {
            addScript({
              src: 'https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js',
            })
            this.hitCount = response.hitCount
            const { hitCount } = this

            this.changeLastSearch({
              query,
              sort: currentSortBy,
              page,
              cursorMark,
              hitCount,
            })

            this.nextCursorMark = response.nextCursorMark
            this.citations = response.resultList.result.slice(
              page > currentPage
                ? nextCursorMark === '*'
                  ? pageGap * pageLimit
                  : (pageGap - 1) * pageLimit
                : (page - 1) * pageLimit
            )
            this.currentPage = parseInt(page, 10)
            const makeSchemaList = this.citations.map((pub, i) => ({
              '@type': 'ListItem',
              position: i + 1,
              url: `${EPMC_ROOT}article/${pub.source}/${pub.id}`,
            }))
            this.schemaList['itemListElement'] = makeSchemaList

            if (hitCount) {
              // add to recent searches
              const snippetSorterMapper = {
                'FIRST_PDATE_D+desc': 'DATE+DESC',
                'FIRST_PDATE_D+asc': 'DATE+ASC',
                'CITED+desc': 'Times+Cited+DESC',
              }
              fetchSnippets({
                cursorMark,
                pagesize: pageSize,
                query,
                sortby: currentSortBy ? snippetSorterMapper[currentSortBy] : '',
              }).then((response) => {
                if (response) {
                  this.changeSnippets(response)
                  this.citations.forEach((citation) => {
                    citation.title = stripMMLPrefix(citation.title)
                    const latexString = citation.title
                    convertLatexToMathMl(latexString).then((updatedString) => {
                      citation.title = updatedString
                    })
                    const aid =
                      citation.source.toLowerCase() + citation.id.toLowerCase()
                    if (response[aid]) {
                      this.$set(
                        citation,
                        'snippets',
                        response[aid]
                          .join('... ')
                          .replace(/<em>/g, '<b>')
                          .replace(/<\/em>/g, '</b>')
                          .replace(/&lt;/g, '<')
                          .replace(/&gt;/g, '>')
                          .replace(/<&#x2F;/g, '</')
                          .replace(/&#x2F;>/g, '/>')
                          .replace(/<h[1-6]>/gi, ' ')
                          .replace(/<\/h[1-6]>/gi, ' ')
                      )
                    }
                  })
                }
              })

              // highlight title
              this.citations
                .filter((citation) => citation.title)
                .forEach((citation, index) => {
                  citation.title = highlightByQuery(query, citation.title, {
                    validFields: SEARCH_FIELDS,
                    highlightedFields: ['TITLE', '<implicit>'],
                    highlightClass: 'extra-bold',
                    highlightIdPattern: 'highlight-' + index + '-',
                  })
                })
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    onSorterSelected(option) {
      const { value } = option

      this.changeLastSortBy(value)
      this.$router.push({
        path:
          'betaSearch' +
          replaceUrlQuery({ page: 1, sortBy: value, maxPageJump: '' }),
      })

      let eventName = ''
      if (value.includes('FIRST_PDATE_D')) {
        eventName = 'Date'
      } else if (value.includes('CITED')) {
        eventName = 'Times Cited'
      } else if (value.includes('FIRST_IDATE')) {
        eventName = 'Date indexed'
      } else {
        eventName = 'Relevance'
      }
      registerMatomoEvent('Search', 'Sort by', eventName)
    },
    onPageEntered({ value, type }) {
      this.$router.push({
        path: 'betaSearch' + replaceUrlQuery({ page: value, maxPageJump: '' }),
      })

      let eventName
      if (type == 'ellipsis') {
        eventName = 'Dots'
      } else if (type == 'prev') {
        eventName = 'Prev'
      } else if (type == 'next') {
        eventName = 'Next'
      } else {
        eventName = 'Page number'
      }
      registerMatomoEvent('Search', 'Pagination', eventName)
    },
    updateDatePublishedSorter() {
      this.currentSortOption.value =
        this.currentSortOption.value === 'FIRST_PDATE_D+desc'
          ? 'FIRST_PDATE_D+asc'
          : 'FIRST_PDATE_D+desc'
      this.$router.push({
        path:
          'betaSearch' +
          replaceUrlQuery({ sortBy: this.currentSortOption.value }),
      })
    },
  },
}
</script>
<template>
  <div id="search-page">
    <script type="application/ld+json">
      {{ schemaList }}
    </script>
    <div id="search-page-content" class="grid-row">
      <div class="col-3 col-l-4 col-s-5 sticky">
        <beta-search-filters
          v-if="query && screenWidth > 799"
          :query="query"
          class="search-filters"
        />
      </div>
      <div class="col-10 col-l-9 col-s-11">
        <div class="search-results">
          <notification v-if="maxPageJump" class="search-action-notify">
            We are unable to support jumping forward more than
            {{ maxPageJumpLimit }} pages at a time. You have been brought to the
            closest allowed results page.
          </notification>
          <notification
            v-if="notify"
            :notification-style="notify.type"
            class="search-action-notify"
          >
            {{ notify.message }}
          </notification>
          <notification v-if="previousQuery" class="search-replacement">
            Showing results for <span>{{ query }}</span> (your query
            <span>{{ previousQuery }}</span> returned 0 results)
          </notification>
          <suggested-authors
            v-if="query"
            :query="queryWithoutConstraints"
            class="search-info-box"
          />
          <author-info v-if="query" :query="queryWithoutConstraints" />
          <div v-if="hitCount" id="search-results--results--details--top">
            {{ ((currentPage - 1) * pageLimit + 1).toLocaleString() }}-{{
              currentPage * pageLimit > hitCount
                ? hitCount.toLocaleString()
                : (currentPage * pageLimit).toLocaleString()
            }}
            of
            <span class="semi-bold">{{ hitCount.toLocaleString() }}</span>
            results
          </div>
          <suggested-search
            v-if="query && !loading"
            class="suggested-search"
            :query="query"
            :hit-count="hitCount"
          />
          <hr v-if="hitCount" class="separator thick" />

          <notification v-if="!loading && !hitCount">
            There are no citations matching your query.
          </notification>
          <template v-else>
            <div class="search-results-bar">
              <div id="search-results-sorter">
                <label for="search-results--sort--by">Sort by: </label>
                <template>
                  <div id="search-results--sorting--options">
                    <div
                      v-for="item in filterOptions"
                      :key="item.value"
                      style="display: flex; align-items: baseline"
                      @click="onSorterSelected(item)"
                    >
                      <input
                        type="radio"
                        :value="item.value"
                        :name="'option'"
                        :checked="item.selected"
                        @change="onSorterSelected(item)"
                      />
                      <label
                        >{{ item.text }}
                        <sup>
                          <tooltip v-if="item.value === 'Relevance'">
                            <i slot="trigger" class="far fa-question-circle" />
                            <b>Relevance:</b> {{ item.tooltipText }}
                          </tooltip>
                        </sup>
                      </label>
                    </div>
                  </div>
                </template>
                <action
                  v-if="currentSortOption.text === 'Date'"
                  id="date-published-arrow"
                  @click.prevent="updateDatePublishedSorter"
                >
                  <i
                    slot="icon"
                    :class="[
                      'fas',
                      currentSortOption.value === 'FIRST_PDATE_D+desc'
                        ? 'fa-arrow-up'
                        : 'fa-arrow-down',
                    ]"
                  />
                </action>
              </div>
              <pagination
                v-if="hitCount > pageLimit"
                id="search-results--pagination"
                :total-size="hitCount"
                :page-size="pageLimit"
                :max-number-of-displayed-pages="3"
                :current-page="currentPage"
                @onPageEntered="onPageEntered"
              />
            </div>
            <list v-if="!loading" :list="citations" :separator-below="true">
              <citation
                :id="'search-results--single--block-' + item.id"
                slot-scope="{ item }"
                :citation="item"
              >
                <div slot="middle-slot" v-html="item.snippets" />
                <template slot="label-slot">
                  <action
                    v-if="
                      exportList.find(
                        (art) =>
                          art.source === item.source && art.id === item.id
                      )
                    "
                    @click="removeSelectedArticle(item)"
                  >
                    <i slot="icon" class="fas fa-check" />Added to export list
                  </action>
                  <tooltip v-else-if="exportList.length === EXPORT_LIST_LIMIT">
                    <action slot="trigger" :disabled="true">
                      <i slot="icon" class="fas fa-plus" />Add to export list
                    </action>
                    The export list is full!
                  </tooltip>
                  <action
                    v-else
                    :id="'search-results--addTo--exportList-' + item.id"
                    @click="addSelectedArticle(item)"
                  >
                    <i slot="icon" class="fas fa-plus" />Add to export list
                  </action>
                </template>
              </citation>
            </list>
            <loading v-else />
            <div v-if="!loading" class="search-results-bar">
              <div id="search-results--results--details--bottom">
                {{ ((currentPage - 1) * pageLimit + 1).toLocaleString() }}-{{
                  currentPage * pageLimit > hitCount
                    ? hitCount.toLocaleString()
                    : (currentPage * pageLimit).toLocaleString()
                }}
                of
                <span class="semi-bold">{{ hitCount.toLocaleString() }}</span>
                results
              </div>
              <pagination
                v-if="hitCount > pageLimit"
                :total-size="hitCount"
                :page-size="pageLimit"
                :max-number-of-displayed-pages="3"
                :current-page="currentPage"
                @onPageEntered="onPageEntered"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="col-3 sticky">
        <action-bar
          v-if="query && screenWidth > 1000"
          @notify="(n) => (notify = n)"
        />
      </div>
    </div>
    <sticky-footer :screen-width="screenWidth" @notify="(n) => (notify = n)">
      <search-filters
        v-if="query"
        slot="menu1"
        ref="Filter"
        :query="query"
        :mobile="true"
      />
    </sticky-footer>
  </div>
</template>
<style lang="scss">
.citation-title mjx-container[jax='CHTML'] {
  display: inline !important;
}
.citation-title math[display='block' i] {
  display: inline-block !important;
}
#search-page {
  h2.alt-text {
    display: block;
  }
  .search-filters {
    margin-top: $base-unit * 9;
  }
  .search-results {
    margin-top: $base-unit * 9.5;
    .search-action-notify {
      margin: -($base-unit * 6) 0 ($base-unit * 3);
    }
    .search-info-box {
      margin-bottom: $base-unit * 8;
    }
    .suggested-search {
      margin-top: $base-unit * 2;
    }
    .separator {
      margin-top: $base-unit;
    }
    .search-results-bar {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;
      & > * {
        margin: ($base-unit * 2) 0;
      }
      .dropdown-v3 {
        margin-right: $base-unit * 2;
        width: $base-unit * 52;
      }
    }
  }
  #search-page-content {
    padding-bottom: $base-unit * 6;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-height: 100vh;
    z-index: 3;
  }
  label[for='search-results--sort--by'] {
    margin-right: $base-unit * 2;
  }
  #search-results--sorting--options {
    display: flex;
    label {
      margin-right: $base-unit * 2;
    }
    input {
      margin-right: $base-unit;
    }
    i {
      font-size: $base-unit * 3;
    }
  }
  #date-published-arrow {
    display: inline-block;
  }
  #search-results-sorter {
    display: flex;
    align-items: center;
    @media screen and (max-width: 799px) {
      width: 100%;
      .dropdown-v3 {
        width: auto !important;
        flex-grow: 2;
      }
    }
  }
}
</style>
